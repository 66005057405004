import axios from '@/services/axios_auth'

export const usePurchaseService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const purchase_api = axios.create({
    baseURL: useRuntimeConfig().public.PURCHASE_API || (baseurl + '/purchase-api/1.0.0')
  })

  const getProductQuantityRounding = async function(sku, qty) {
    const res = await purchase_api.get(`/products(${sku})/quantities(${qty})`)
      .catch(() => { return null })
    return res && res.data ? res.data : null
  }

  const getProductQuantityAdjustments = async function(sku, qty) {
    const res = await purchase_api.get(`/products(${sku})/adjustments?$filter=quantity eq ${qty}`)
      .catch(() => { return null })
    return res && res.data ? res.data : null
  }

  const getForwarders = async function() {
    const res = await purchase_api.get('/downloads/forwarders')
      .catch(() => { return null })
    return res && res.data ? res.data : null
  }

  async function postXpress(request) {
    if (request !== null) {
      const res = await purchase_api.post('/xpress', request).catch(() => {return null})
      if (res?.status >= 200 && res?.status <= 299) {
        return true
      }
    }
    return false
  }

  return {
    getProductQuantityRounding,
    getProductQuantityAdjustments,
    getForwarders,
    postXpress
  }
}
